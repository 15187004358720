import { MeetingType } from './meetingType.enum';
import { AttendanceInfo } from './course-student-attendance.model';
import { Range } from './date-picker.model';
import { AttachmentModel } from './attachment-table.model';

export class MeetingSearch {
    constructor(
        public name?: string,
        public description?: string,
        public dateRange?: Range,
        public statuses?: MeetingStatusType[],
        public schoolId?: string,
        public pageSize?: number,
        public pageIndex?: number
    ) {}
}

export interface IMeetingInfo {
    createTime: Date;
    endTime: Date;
    duration: number;
    running: boolean;
    recording: boolean;
    recordingReady: boolean;
    isBreakout: boolean;
}

export interface IMeeting {
    id?: string;
    deleted?: boolean;
    schoolId?: string;
    description?: string;
    //settings?: BBBServerSetting;
    date?: Date;
    startTime?: string;
    endTime?: string;
    moderators?: Subscriber[];
    attendees?: Subscriber[];
    status?: MeetingStatusType;
    recordings?: Recording[];
    isModerator?: boolean;
    attachments?: AttachmentModel[];
    meetingInfo?: IMeetingInfo;
    hasMeetingInfo?: boolean;
    participants?: Subscriber[];
    isCourseMeeting?: boolean;
    type?: MeetingType;
    enableAttendance?: boolean;
    hookId?: string;
    location?: string;
}

export class Meeting implements IMeeting {
    constructor(
        public id?: string,
        public deleted?: boolean,
        public schoolId?: string,
        public description?: string,
        //public settings?: BBBServerSetting,
        public date?: Date,
        public startTime?: string,
        public endTime?: string,
        public moderators?: Subscriber[],
        public attendees?: Subscriber[],
        public status?: MeetingStatusType,
        public recordings?: Recording[],
        public isModerator?: boolean,
        public attachments?: AttachmentModel[],
        public participants?: Subscriber[],
        public isCourseMeeting?: boolean,
        public type?: MeetingType,
        public enableAttendance?: boolean,
        public hookId?: string,
        public location?: string
    ) {
        this.id = id ? id : null;
        this.deleted = deleted ? deleted : false;
        this.schoolId = schoolId ? schoolId : '';
        this.description = description ? description : '';
        //this.settings = settings ? settings : new BBBServerSetting();
        this.date = date ? date : new Date();
        this.startTime = startTime ? startTime : null;
        this.endTime = endTime ? endTime : null;
        this.moderators = moderators ? moderators : [];
        this.attendees = attendees ? attendees : [];
        this.status = status ? status : MeetingStatusType.CREATED;
        this.recordings = recordings ? recordings : [];
        this.isModerator = isModerator ? isModerator : false;
        this.attachments = attachments ? attachments : [];
        this.participants = participants ? participants : [];
        this.isCourseMeeting = isCourseMeeting ? isCourseMeeting : false;
        this.type = type ? type : null;
        this.enableAttendance = enableAttendance ? enableAttendance : true;
        this.hookId = hookId ?? null;
        this.location = location ?? null;
    }
}

export class Subscriber {
    constructor(
        public id?: string,
        public name?: string,
        public type?: SubscriberType,
        public data?: any,
        public joinTime?: Date,
        public leftTime?: Date,
        public duration?: number, // seconds
        public attended?: boolean,
        public tardy?: boolean,
        public attendanceInfo?: AttendanceInfo,
        public recordingViewed?: boolean,
        public recordingViewDuration?: number,
        public moderator?: boolean,
        public leftCount?: number
    ) {
        this.id = id ? id : null;
        this.name = name ? name : null;
        this.type = type ? type : null;
        this.data = data ? data : undefined;
        this.joinTime = joinTime ? joinTime : null;
        this.leftTime = leftTime ? leftTime : null;
        this.duration = duration ? duration : null; // seconds
        this.attended = attended ? attended : false;
        this.tardy = tardy ? tardy : false;
        this.moderator = moderator ? moderator : false;
        this.attendanceInfo = attendanceInfo ? attendanceInfo : new AttendanceInfo();
        this.recordingViewed = recordingViewed ? recordingViewed : false;
        this.recordingViewDuration = recordingViewDuration ? recordingViewDuration : 0;
        this.leftCount = leftCount ?? 0;
    }
}

export class Recording {
    constructor(
        public id?: string,
        public url?: string,
        public hidden?: boolean
    ) {
        this.id = id ? id : null;
        this.url = url ? url : null;
        this.hidden = hidden ? hidden : false;
    }
}

export class ExtraRecordingMembersSearch {
    constructor(
        public recordingIds?: string[],
        public extraRecordingMembers?: Subscriber[]
    ) {
        this.recordingIds = recordingIds ? recordingIds : [];
        this.extraRecordingMembers = extraRecordingMembers ? extraRecordingMembers : [];
    }
}

export interface IJoinMeetingResponse {
    id?: string;
    name?: string;
    url?: string;
    startTime?: Date;
    endTime?: Date;
    createWebhook?: boolean;
}

export class JoinMeetingResponse implements IJoinMeetingResponse {
    constructor(
        public id?: string,
        public name?: string,
        public url?: string,
        public startTime?: Date,
        public endTime?: Date
    ) {
        this.id = id ? id : null;
        this.name = name ? name : null;
        this.url = url ? url : null;
        this.startTime = startTime ? startTime : null;
        this.endTime = endTime ? endTime : null;
    }
}

export interface ICalendarEventInfo {
    eventDate?: Date;
    moderator?: boolean;
    eventId?: string;
    eventStartTime?: Date;
    eventEndTime?: Date;
    eventTitle?: string;
}

export interface ICalendarEventTodayMeetings {
    calendarEvents?: ICalendarEventInfo[];
    list?: IJoinMeetingResponse[];
}

export interface IPageableMeeting {
    id?: string;
    name?: string;
    status?: MeetingStatusType;
    date?: Date;
    startTime?: Date;
    endTime?: Date;
    hasMeetingInfo?: boolean;
    recordingReady?: boolean;
    recordingHidden?: boolean;
    currentUserModerator?: boolean;
    currentUserAttendee?: boolean;
    allowAttendeeWatchRecording?: boolean;
    isCourseMeeting?: boolean;
    currentUserWatched?: boolean;
}

export enum SubscriberType {
    USER = 'USER',
    ROLE = 'ROLE',
    CLASS = 'CLASS',
    COURSE = 'COURSE',
    EMPLOYEE = 'EMPLOYEE',
}

export enum MeetingStatusType {
    CREATED = 'CREATED',
    PUBLISHED = 'PUBLISHED',
    STARTED = 'STARTED',
    ENDED = 'ENDED',
    CANCELLED = 'CANCELLED',
}
